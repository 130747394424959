<template>
  <v-app id="login">
    <v-main
      class="login-panel pt-15"
      :style="{ backgroundImage: `url(${backgroundUrl})` }"
    >
      <v-container fluid class="pt-15">
        <template>
          <v-row justify="center" class="pt-5 text-center">
            <v-card width="400" class="mx-auto pa-4 py-3">
              <h3 class="primary--text font-weight-medium pt-5">
                Congratulaitons!!
              </h3>

              <v-card-text class="primary--text pt-1">
                Your Account Has Been Activated
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="primary darken-1"
                  outlined
                  block
                  @click="submit"
                  :loading="loader"
                >
                  OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-row>
        </template>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import authAPI from "@/services/authAPI.js";
import backgroundUrl from "../../assets/images/didpanel.jpg";
export default {
  name: "emailVerificcation",
  data() {
    return {
      //fullPage: false,
      backgroundUrl: "",
      queryParams: {},
      loader: false,
    };
  },
  mounted() {
    this.backgroundUrl = backgroundUrl;
  },

  methods: {
    async submit() {
      this.loader = true;
      try {
        let str = window.location.search.replace("?", "");
        let arr = str.split("&");
        for (let item of arr) {
          let split = item.split("=");
          this.queryParams[split[0]] = split[1];
        }
        let res = await authAPI.verifyUser(this.queryParams);
        if (res) {
          location.href = "/";;
          this.loader = false;
        }
      } catch (error) {
        this.loader = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: error.data.messageDetail,
          color: "red",
        });
      }
    },
  },
};
</script>
